
import React from 'react'
import Chatbot from '../../../templates/chatbot'

const ChatbotEsPage = () => {
    return (
        <Chatbot lang="es">
        </Chatbot>
    )
}

export default ChatbotEsPage
